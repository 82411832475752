import {
  FormControl,
  FormLabel,
  Input,
  Select,
  Stack,
  Td,
  Text,
  Th,
  Tr,
  VStack,
} from '@chakra-ui/react'
import { graphql, useStaticQuery } from 'gatsby'
import uniqBy from 'lodash/uniqBy'
import React, { useEffect, useState } from 'react'
import { Container, ResultsTable } from 'starterUiComponents'

const OBD_QUERY = graphql`
  query {
    allObd {
      nodes {
        code
        description
        id
        make
      }
    }
  }
`

const OBDIIPage = () => {
  const data = useStaticQuery(OBD_QUERY)
  const odbObjects = data.allObd.nodes
  const letters = ['P', 'U', 'C', 'B']

  const [selections, setSelections] = useState({
    letter: '',
    number: '',
  })

  const [results, setResults] = useState([])

  const { letter, number } = selections

  useEffect(() => {
    const code = letter + number.toUpperCase()
    const getResults = (code) => {
      return uniqBy(
        odbObjects.filter((item) => item.code === code),
        'make'
      )
    }
    setResults(getResults(code))
  }, [selections, letter, number, odbObjects])

  const onSelectChange = (e) => {
    setSelections({
      ...selections,
      [e.target.name]: e.target.value,
    })
  }

  const noResults =
    letter === '' || number === ''
      ? 'Please enter your information in the fields above to search for results.'
      : 'No information is available for your selection(s), please try your search again with another selection.'

  return (
    <Container>
      <Text className="font-bold font-heading text-l">
        Enter the code you want to look up:
      </Text>
      <form>
        <div className="p-8 mb-5 bg-darkBlue">
          <Stack
            direction={{ base: 'column', lg: 'row' }}
            spacing={5}
            w={{ base: '100%', lg: '50%' }}
            sx={{
              label: {
                color: 'white',
                fontStyle: 'italic',
                mb: 2,
                fontSize: 'base',
              },
              '.chakra-stack': {
                width: '100%',
              },
            }}
          >
            <VStack>
              <FormControl id="letter">
                <FormLabel>Code Type</FormLabel>
                <Select
                  name="letter"
                  layerStyle="input"
                  placeholder="Choose a code type"
                  onChange={(e) => onSelectChange(e)}
                >
                  {letters.map((letter) => (
                    <option key={letter} value={letter}>
                      {letter}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </VStack>
            <VStack>
              <FormControl id="number">
                <FormLabel>Code Number</FormLabel>
                <Input
                  placeholder="Enter Code Number"
                  name="number"
                  onChange={(e) => onSelectChange(e)}
                />
              </FormControl>
            </VStack>
          </Stack>
        </div>
      </form>

      <ResultsTable
        sx={{ tr: { display: 'block' } }}
        headers={
          <>
            <Th w={{ base: '33%', md: '200px' }}>Code</Th>
            <Th w={{ base: '33%', md: '200px' }}>Make</Th>
            <Th w={{ base: '33%', md: '400px' }}>Description</Th>
          </>
        }
      >
        {results.length > 0 ? (
          results.map((item) => {
            const { id, code, make, description } = item
            return (
              <Tr key={id} sx={{ '&:first-of-type': { pt: 5 } }}>
                <Td
                  w={{ base: '33%', md: '200px' }}
                  className="text-sm uppercase"
                >
                  {code}
                </Td>
                <Td w={{ base: '33%', md: '200px' }} fontSize="sm">
                  {make}
                </Td>
                <Td w={{ base: '33%', md: '500px' }} fontSize="sm">
                  {description}
                </Td>
              </Tr>
            )
          })
        ) : (
          <Tr className="mt-5">
            <Td>{noResults}</Td>
          </Tr>
        )}
      </ResultsTable>
    </Container>
  )
}

export default OBDIIPage
